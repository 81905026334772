import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';
import useAuth from '../util/hooks/useAuth';
import SEO from '../components/SEO';
import VirtualTour from '../components/global/VirtualTour';

const VirtualToursPage = ({ data }) => {
  useAuth();
  const [device, setDevice] = useState('mobile');
  const { edges: virtaulTours } = data.allNodeVirtualTour;
  const [tours, setTours] = useState(virtaulTours);

  React.useEffect(() => {
    if (typeof window !== 'undefined'){
      const params = new URLSearchParams(window.location.search);
      const idParam = params.get("id");

      if (idParam) {
        setTours(virtaulTours.filter((i) => {
          if (i?.node?.id) {
            return i.node.id === idParam
          } else {
            return false;
          }
        }))
      } 
    }
  }, [])

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      footerMenu={data.footer_menu}
      menu={data.allMenuMenuLink}
      locations={data.locations.edges}
    >
      <SEO title="Virtual Tours" />
      <main className="virtual-tours-page">
        <section className="grid-section grid-content-container">
          <div className="t-heading-container">
            <h2>Virtual Tours</h2>
          </div>
        </section>
        {tours.map(({ node: virtualTour }) => {
    
          return (
            <section
              key={virtualTour.id}
              className="grid-section grid-content-container"
            >
              <VirtualTour virtualTour={virtualTour} device={device} />
            </section>
          )})}
        <div className="spacer"></div>
      </main>
    </Layout>
  );
};

export default VirtualToursPage;

export const VirtualToursPageQuery = graphql`
  query VirtualToursPageQuery {
    allNodeVirtualTour(
      sort: { fields: relationships___field_exhibition___created, order: DESC }
    ) {
      edges {
        node {
          id
          title
          field_embedded_code
          body {
            processed
          }
          relationships {
            field_exhibition {
              id
              title
              field_dates {
                value
                end_value
              }
              path {
                alias
              }
              title
              relationships {
                field_locations_ref {
                  title
                }
              }
            }
          }
        }
      }
    }
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
  }
`;

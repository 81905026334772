const dateFormat = (isoDate, includeYear) => {
    const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ];
    const weekDay = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    let date = new Date(new Date(isoDate).toLocaleString("en-US", {timeZone: 'America/New_York'}));
    let day = date.getUTCDate();
    let monthIndex = date.getUTCMonth();
    let year = date.getUTCFullYear();
    let weekDayIndex = date.getUTCDay();
    let formattedDate = includeYear ? weekDay[weekDayIndex] + ", "+ monthNames[monthIndex] + ' ' + (day) + ' ' + year : monthNames[monthIndex] + ' ' + (day)
    
    return formattedDate;
}

export default dateFormat;

import React, { useState, useEffect, useRef } from 'react';
import { useWindowResize } from '../../util/hooks/useWindowResize';

export const ReadMore = ({ children, className = '', refEle = null }) => {
  const [readMore, setReadMore] = useState(false);
  const [images, setImages] = useState([]);
  const [maxHeight, setMaxHeight] = useState('');
  const [refHeight, setRefHeight] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const childrenWrapper = useRef(null);
  const [isRefLarger, setIsRefLarger] = useState(false);
  const windowSize = useWindowResize();

  useEffect(() => {
    if (refEle && childrenWrapper) {
      if (refEle.current && childrenWrapper.current) {
        setRefHeight(refEle.current.offsetHeight);
        setIsRefLarger(
          refEle.current.offsetHeight > childrenWrapper.current.offsetHeight
        );
      }
    }
  }, [refEle, childrenWrapper, windowSize, imagesLoaded]);

  useEffect(() => {
    if (refEle && childrenWrapper) {
      if (refEle.current && childrenWrapper.current) {
        const images = Array.from(refEle.current.querySelectorAll('img'));
        setImages(() => [...images]);
      }
    }
  }, [refEle, childrenWrapper]);

  const callback = (mutationsList) => {
    setImagesLoaded(
      mutationsList.every((mutation) => {
        if (
          mutation.attributeName === 'class' &&
          mutation.target.classList.contains('loaded')
        ) {
          return true;
        }
        return false;
      })
    );
  };

  useEffect(() => {
    let mutationObserver;

    if (images.length) {
      mutationObserver = new MutationObserver(callback);
      images
        .filter((image) => image.classList.contains('anim-img'))
        .forEach((image) => {
          mutationObserver.observe(image, { attributes: true });
        });
    }

    return () => {
      if (typeof mutationObserver !== 'undefined') {
        mutationObserver.disconnect();
      }
    };
  }, [images]);

  useEffect(() => {
    if (readMore) {
      setMaxHeight('100%');
    } else {
      if (!refEle) {
        if (childrenWrapper.current) {
          const { offsetHeight, style } = childrenWrapper.current;
          let newHeight =
            style.lineHeight * Number(style.fontSize.slice(0, -2));

          if (offsetHeight / newHeight < 5) {
            setMaxHeight('100%');
            setReadMore(true);
          } else {
            setMaxHeight(`${newHeight * 5}px`);
          }
        }
      } else {
        if (isRefLarger) {
          setMaxHeight('100%');
        } else {
          setMaxHeight(`${refHeight * 0.75}px`);
        }
      }
    }
  }, [readMore, refEle, isRefLarger, refHeight, childrenWrapper]);

  return (
    <div
      className={`view-more-wrapper ${
        !isRefLarger || !refEle ? 'is-active' : ''
      } ${readMore ? 'is-open' : ''} ${className}`}
      style={
        maxHeight
          ? {
              maxHeight,
            }
          : {}
      }
    >
      <div ref={childrenWrapper} style={{ lineHeight: 1.5, fontSize: `23px` }}>
        {children}
      </div>
      {!isRefLarger || !refEle ? (
        <div className="read-more-button">
          <a onClick={() => setReadMore(!readMore)}>Read More</a>
        </div>
      ) : null}
    </div>
  );
};

import React from 'react';
import dateFormat from '../helper/dateFormat';
import CompLink from './Link';
import { ReadMore } from './ReadMore';

export const ExibHeadLine = ({ device, field_exhibition }) => {
  if (!field_exhibition) return null;

  return (
    <div className="grid-2">
      <div className="grid-col">
        <h3 className="t-large t-subheading t-uppercase exh-title">
          {field_exhibition.title}
        </h3>
        <h4 className="t-medium t-subheading t-uppercase exh-date">
          {field_exhibition.field_dates ? (
            <>
              {dateFormat(field_exhibition.field_dates.value)} -{' '}
              {dateFormat(field_exhibition.field_dates.end_value)}
            </>
          ) : null}
          {field_exhibition?.relationships?.field_locations_ref?.length > 0
            ? field_exhibition?.relationships?.field_locations_ref
                .map((ele) => ele.title.split(',')[1])
                .join(', ')
            : 'Virtual'}
        </h4>
      </div>
      {field_exhibition?.path ? (
        <div className="grid-col justify-end align-center exh-btn">
          <CompLink
            button
            className={`btn-medium btn-center`}
            path={field_exhibition?.path?.alias}
            linkkey={`exhib_btn`}
            device={device}
          >
            View Exhibition
          </CompLink>
        </div>
      ) : null}
    </div>
  );
};

const VirtualTour = ({ virtualTour, device }) => {
  const { field_exhibition } = virtualTour.relationships;

  return (
    <div className="virtual-tour">
      <ExibHeadLine field_exhibition={field_exhibition} device={device} />
      <div
        className={`iframe-wrapper`}
        dangerouslySetInnerHTML={{
          __html: virtualTour?.field_embedded_code,
        }}
      ></div>
      {virtualTour?.body?.processed ? (
        <div className="virtual-tour-description-wrapper">
          <ReadMore>
            <span
              className="virtual-tour-description"
              dangerouslySetInnerHTML={{
                __html: virtualTour?.body?.processed,
              }}
            ></span>
          </ReadMore>
        </div>
      ) : null}
    </div>
  );
};

export default VirtualTour;
